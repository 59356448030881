if (!AbortSignal.timeout) {
  AbortSignal.timeout = function (ms) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(new DOMException('TimeoutError')), ms);

    return controller.signal;
  };
}

// @ts-ignore A versão que usamos do TS ainda não tem essa tipagem
if (!AbortSignal.any) {
  // @ts-ignore A versão que usamos do TS ainda não tem essa tipagem
  AbortSignal.any = function (signals: AbortSignal[]): AbortSignal {
    if (signals.length === 1) {
      return signals[0];
    }

    const controller = new AbortController();

    const onAbort = () => {
      controller.abort();
      signals.forEach((signal) => signal.removeEventListener('abort', onAbort));
    };

    signals.forEach((signal) => {
      if (signal.aborted) {
        controller.abort();
      } else {
        signal.addEventListener('abort', onAbort);
      }
    });

    return controller.signal;
  };
}
